:root {
  --text-secondary: #f4f4f4;
  --top-bar-height: 55px;
}

.home-root {
  flex-grow: 1;
}

.home-app {
  overflow: hidden;

}

.home-topBar {
  height: var(--top-bar-height);
  opacity: 99% !important;
  background-color: #1a1919 !important;
  justify-content: center;
  padding-right: 30px;
  padding-left: 30px;
}

.home-toolBar {
  min-height: 50px;
}

.home-topBarLogo {
  margin: 0;
  height: var(--top-bar-height);
  width: var(--top-bar-height);
}

/* Home background Image - Start */
/* Global container */
.home-container {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* Background image */
.home-background-image {
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  filter: brightness(50%);
}

/* Overlay section */
.home-overlay-section {
  height: calc(100vh);
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 1;
}

.home-overlay-title {
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1.5;
  margin: 0 20px;
  text-align: center;
}

.home-scroll-indicator {
  position: absolute;
  bottom: 20px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.scroll-indicator:hover {
  transform: scale(1.03);
}

.chevron-icon {
  fill: #363535;
  width: 30px;
  height: 30px;
}
/* Home background Image - END */


.home-unitWrapper {
  position: relative;
  z-index: 2; /* Place au-dessus de l'image et de la superposition */
  padding-top: 20px; /* Ajustez selon vos besoins */
}

.home-welcomeZone {
  height: calc(100vh - var(--top-bar-height));
  margin-top: var(--top-bar-height);
  background-image: url('../../images/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-self: stretch;
}

.home-welcomeZoneFilter {
  line-height: 1.2;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-welcomeZoneFilter::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1919;
  opacity: 0.75;
  z-index: -1;
}

.home-welcome-content {
  padding-bottom: 40px;
  text-align: center;
}

.home-welcomeTitleZone {
  padding-top: calc(var(--top-bar-height) + 30px);
  margin-bottom: 0;
}

.home-welcomeSubTitleZone {
  margin-bottom: 50px;
}

.home-appContentTextZone {
  word-wrap: break-word;
  max-width: 700px;
  padding-left: 3%;
  padding-right: 3%;
  float: left;
  margin-bottom: 50px;
}

.home-calendarBtnWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-calendarBtnIcon {
  width: 35px;
  height: 35px;
  margin-right: 8px;
  fill: #f2f2f2;
}

.home-content {
  background-color: #f2f2f2;
  width: '100%';
}