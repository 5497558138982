.info-titleZone {
  text-align: center;
  margin-bottom: 30px;
}

.info-dayZone {
  width: 200px;
}

.info-dayScheduleZone {
  display: flex;
  align-items: center;
}

.info-zone {
  background-color: #ffffff;
  padding: 15px;
}

.info-infoZone {
  flex: 1;
  justify-content: center;
  text-align: center;
}

.info-icon {
  width: 35px;
  height: 35px;
}

.info-infoIconZone {
  width: 80px;
  height: 80px;
  background-color: #b5b4b4;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-iconZone {
  background-color: #1a1919;
  margin-bottom: 10px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-separator {
  height: 2px;
  border-radius: 10px;
  color: #b5b4b4;
  background-color: #b5b4b4;
  border-width: 0;
}

.info-infoSeparator {
  height: 2px;
  border-radius: 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 110px;
  color: #b5b4b4;
  background-color: #b5b4b4;
  border-width: 0;
}

.info-subTitleZone {
  margin-bottom: 20px;
}