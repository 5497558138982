.team-zone {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 0px) {
  .team-zone {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 600px) {
  .team-zone {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (min-width: 900px) {
  .team-zone {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1280px) {
  .team-zone {
    padding-left: 10%;
    padding-right: 10%;
  }
}

.team-titleZone {
  float: left;
  margin-bottom: 40px;
}

.team-contentZone {
  text-align: center;
}

.team-contentTitleZone {
  margin-bottom: 20px;
}

.team-contentTextZone {
  margin-bottom: 10px;
}

.team-card {
  display: flex;
  flex-direction: row;
}

.team-cardWrapper {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 480px;
  overflow: hidden;
}

.team-frontCard {
  width: calc(100% - 60px);
  max-width: 95%;
  height: 420px;
  background-color: #1a1919;
  z-index: 2;
  padding: 15px;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.team-backCard {
  width: 100%;
  max-width: 95%;
  height: 450px;
  background-color: rgba(41, 38, 38, 0.97);
  padding: 15px;
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
}

.team-cardHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15px;
}

.team-photoZone {
  display: block;
  height: 250px;
  width: 250px;
  border-radius: 300px;
  background-color: rgba(41, 38, 38, 0.97);
}

.team-photo {
  height: 250px;
  border-radius: 65px;
  pointer-events: none;
}

.team-specialities {
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.team-specialityIconZone {
  padding: 10px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 0px) {
  .team-specialityIconZone {
    width: 35px;
    height: 35px;
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media (min-width: 600px) {
  .team-specialityIconZone {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.team-specialityIcon {
  width: 100%;
  height: 100%;
  fill: #f6f6f6;
  stroke: #f6f6f6;
}