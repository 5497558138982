.services-titleZone {
  float: left;
  margin-left: -35px;
  margin-bottom: 80px;
}

.services-zone {
  margin-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px;
}

@media (min-width: 600px) {
  .services-zone {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 960px) {
  .services-zone {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1280px) {
  .services-zone {
    padding-left: 10%;
    padding-right: 10%;
  }
}

.services-zoneWrapper {
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
  flex: 1;
}

.services-partnershipImage {
  width: 100% !important;
}

.services-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  color: #1a1919;
  font-weight: 500;
}

.services-bottomText {
  margin-bottom: 30px;
}