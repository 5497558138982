:root {
  --card-height: 420px;
  --header-height: 70px;
  --border-radius: 15px;
  --border-radius-inner: 11px;
  --left-line-width: 5px;
  --padding-text-zone: 15px;
  --padding-left-text-zone: calc(var(--left-line-width) + var(--padding-text-zone));
  --background-color-rear: #f2f2f2;
  --background-color-filter: #1a1919;
  --text-color-front: #f2f2f2;
  --text-color-rear: #1a1919;
  --opacity-filter: 0.65;
}

.flip-card-rearZone {
  height: var(--card-height);
  background-color: var(--background-color-rear);
  border-radius: var(--border-radius);
  border-left: var(--left-line-width) solid;
}

.flip-card-rearTextZone {
  padding-right: var(--padding-text-zone);
  padding-left: var(--padding-left-text-zone);
  padding-top: var(--padding-text-zone);
  padding-bottom: var(--padding-text-zone);
}

.flip-card-frontalZoneWrapper {
  height: var(--card-height);
  border-radius: var(--border-radius);
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.flip-card-frontalZone {
  height: var(--card-height);
  border-radius: var(--border-radius-inner);
  background-position: center;
  background-repeat: no-repeat;
  flex: 1;
  resize-mode: cover;
  filter: grayscale(85%) contrast(120%);
}

.flip-card-header {
  margin-bottom: 10px;
  text-align: center;
}

.flip-card-icon {
  height: var(--header-height);
  width: var(--header-height);
}

.flip-card-title {
  color: var(--text-color-front);
  font-size: 26px !important;
  line-height: 1.2;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

.flip-card-filter {
  color: inherit;
  font-size: 30px;
  line-height: 1.2;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  border-radius: var(--border-radius-inner);
  background-color: var(--background-color-filter);
  opacity: var(--opacity-filter);
}

.flip-card-text {
  color: var(--text-color-rear);
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.flip-card-button {
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  width: calc(100% - (var(--padding-left-text-zone) + var(--padding-text-zone) + var(--left-line-width)));
  bottom: var(--padding-text-zone);
}