.not-found-zone {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-color: #1a1919;
}

.not-found-contentZone {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

.not-found-contentTextZone {
  margin-top: 50px;
}

.not-found-image {
  width: 400px;
}