.quotes-Zone {
  width: 100%;
  min-height: 200px;
  background-color: #1a1919;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 30px;
}

.quotes-Wrapper {
  width: 100%;
  padding-left: 90px; /* 40 + 50 */
  padding-right: 40px;
  margin-bottom: 0;
}

.quotes-WrapperMobile {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0;
}

.quotes-LeftZone {
  max-width: 950px;
  word-wrap: break-word;
  float: left;
}

.quotes-RightZone {
  max-width: 700px;
  word-wrap: break-word;
  float: right;
}

.quotes-ZoneMobile {
  word-wrap: break-word;
}

.quotes-Mark {
  color: #393838;
  font-size: 90px;
  font-family: 'Montserrat', sans-serif;
  max-width: 850px;
  font-weight: bold;
  margin-bottom: -50px;
  margin-left: -40px;
}

.quotes-MarkMobile {
  color: #393838;
  font-size: 90px;
  font-family: 'Montserrat', sans-serif;
  max-width: 850px;
  font-weight: bold;
  margin-bottom: -50px;
}

.quotes-AuthorImage {
  display: inline-block;
  width: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.quotes-AuthorNameZone {
  margin-top: 20px;
  margin-left: 70px;
}

.quotes-AuthorZone {
  position: relative;
  margin-top: 20px;
}