.btn-zone {
  all: unset;
  display: inline-flex;
  border-radius: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #4FB0C6;
  text-align: center;
  border-width: 0;
  cursor: pointer;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}

.btn-zone:hover {
  border-style: none;
  filter: brightness(90%);
}

.btn-zone:focus {
  outline: none;
}

.btn-label {
  color: #f2f2f2;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}