.treatments-zone {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 5%;
  padding-right: 5%;
  flex: 1;
  background-color: #1a1919;
}

.treatments-titleZone {
  float: right;
  margin-bottom: 50px;
  margin-right: 100px;
}